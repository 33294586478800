
// document.addEventListener('DOMContentLoaded', function () {
//   const mobileMenu = document.getElementById('mobileMenu');

//   mobileMenu.addEventListener('click', function (e) {
//     if (e.target.getAttribute('href').includes('#')) {
//       document.getElementById('menu').click()
//     }
//   })
// })
